import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer } from './ui/Layout'
import Button from './ui/Button'
import Spinner from './ui/Spinner'
import { colors, smallBoxShadow } from './ui/helpers.js'
import { urlToString } from './ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { translate, countryTranslate, activeCountries } from '../../constants/translations'

import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from './ui/Typography'
import OnlineIcon from './ui/OnlineIcon'
import Image from './ui/Image'

const TextButton = styled(Text)`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 24px;
`

const LinkText = ({ url, label }) => {
  return (
    <Link to={url}>
      <TextButton color="white">{label}</TextButton>
    </Link>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  width: 120px;
  min-width: 120px;
  padding-top: 16px;
  background-color: ${colors.darkBlue};
  @media (max-width: 700px) {
    display: none;
  }
`
const FlagImage = styled.img`
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`

const CountryContainer = styled(Flex)`
  max-height: 120px;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  &:hover {
    background-color: ${colors.lightBlue};
  }
`

const Flag = ({ iso2 }) => {
  return <FlagImage src={`/country-flag/${iso2}.svg`} width="30px" height="30px" />
}

const CounterContainer = styled(Flex)`
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 32px;
`

const Counter = ({ count }) => {
  return (
    <CounterContainer>
      <Span fontSize="12px" bold>
        {count}
      </Span>
    </CounterContainer>
  )
}

const ActiveBorder = styled(Box)`
  background-color: white;
  width: 5px;
  height: 100%;
  border-radius: 0px 5px 5px 0px;
  position: absolute;
  left: 0;
  top: 0;
`

const SideMenu = ({ iso2, telephones, country }) => {
  return (
    <Container>
      <Image alt="receiveasmsonline logo" mb="24px" width="110px" height="110px" src="/faviconReverse.svg" />
      <a href={`${iso2 === 'en' ? '' : `/${iso2}`}/`}>
        <CountryContainer mb="32px" flexDirection="row" justifyContent="center">
          <Flex alignItems="center">
            <Image alt="home img" mr="8px" width="20px" height="20px" src="/home.svg" />
            <Text bold color="white">
              {translate(`Home`, 'home', iso2)}
            </Text>
          </Flex>
        </CountryContainer>
      </a>

      <Text mb="8px" bold color="white" px="16px">
        {translate(`Browse by country:`, 'browsenumbercountry', iso2)}
      </Text>
      {Object.keys(telephones).map((otherCountry) => {
        return (
          <a key={otherCountry} href={`${iso2 === 'en' ? '' : `/${iso2}`}/${countryTranslate(otherCountry, iso2)}/`}>
            <CountryContainer>
              {otherCountry === country ? <ActiveBorder /> : null}
              <Flag alt={`${countryToIso2[otherCountry]} flag`} iso2={countryToIso2[otherCountry]} />
              <Counter count={telephones[otherCountry].length} />
              <Text lineHeight="18px" color="white" px={2} textAlign="center" A>
                {urlToString(countryTranslate(otherCountry, iso2))}
              </Text>
            </CountryContainer>
          </a>
        )
      })}
    </Container>
  )
}
export default SideMenu
